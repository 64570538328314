import { z } from 'zod'
import { addressSchema, caseEventCreationTypeSchema, diseaseTypeSchema, filterTemplateGroupSchema, idObjectSchema, idSchema, institutionMemberRoles } from '~/server/schemas'

export const parsePopupPayload = <T extends z.ZodTypeAny>(schema: T, data: unknown): undefined | z.infer<T> => {
  if (typeof data === 'undefined') {
    return
  }

  try {
    return schema.parse(data)
  } catch (error) {
    if (process.dev) {
      if (error instanceof z.ZodError) {
        const isNameMissmatch = error.issues.some(issue => issue.code === 'invalid_literal' && issue.path[0] === 'name')
        if (isNameMissmatch) {
          console.info('Popup: Not opening as names do not match')
        } else {
          console.info('Popup: Not opening as payload is corrupted. Error(s):', error, data)
        }
      }
    }
  }
}

const makeOpener = <T extends z.ZodTypeAny>(schema: T) => {
  const payload = ref<z.infer<typeof schema> | undefined>(undefined)

  return {
    open: (data: z.input<T>) => {
      payload.value = parsePopupPayload(schema, data)
    },
    close: () => {
      payload.value = undefined
    },
    data: payload,
  }
}

/**
 * Schema definition for each opener
 */
const createOrUpdateWithIdSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create') }),
  z.object({ mode: z.literal('update'), id: idSchema }),
])

// Account Opener
const accountOpenerSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create') }),
  z.object({
    mode: z.literal('update'),
    data: z.object({
      id: idSchema,
      showPersonDetails: z.boolean().optional(),
      showAccountDetails: z.boolean().optional(),
      showNameDetails: z.boolean().optional(),
    }),
  }),
])
export type AccountOpener = z.infer<typeof accountOpenerSchema>

// Person Opener
const personPopupOptionsSchema = z.object({
  showName: z.boolean().optional(),
})
const createOrUpdatePersonSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: personPopupOptionsSchema.merge(z.object({
    legalRepresentativeId: idSchema,
  })) }),
  z.object({ mode: z.literal('update'), data: personPopupOptionsSchema.merge(idObjectSchema) }),
])

// Vaccination Opener
const createOrUpdateVaccinationSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal('create'),
    data: z.object({
      personId: idSchema,
    }),
  }),
  z.object({
    mode: z.literal('update'),
    data: z.object({
      id: idSchema,
      personId: idSchema,
    }),
  }),
])

// Case Opener
const createOrUpdateCaseSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal('create'),
    data: z.object({
      type: diseaseTypeSchema,
      patientId: idSchema.nullish(),
      indexCaseId: idSchema.nullish(),
      diseaseId: idSchema.nullish(),
      institutionId: idSchema.nullish(),
    }).default({ type: 'index' }),
  }),
  z.object({
    mode: z.literal('update'),
    data: z.object({
      id: idSchema,
      patientId: idSchema.nullish(),
    }),
  }),
])
export type CaseOpener = z.infer<typeof createOrUpdateCaseSchema>

// Symptom Diary Opener
const createOrUpdateSymptomDiaryEntrySchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal('create'),
    data: z.object({
      case: z.object({ id: idSchema, patientId: idSchema.nullish() }),
    }),
  }),
  z.object({
    mode: z.literal('update'),
    data: z.object({
      id: idSchema,
      case: z.object({ patientId: idSchema.nullish() }).default({}),
    }),
  }),
])

// Case Event Opener
const createOrUpdateCaseEventSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal('create'),
    data: z.object({
      method: caseEventCreationTypeSchema,
      personId: idSchema,
      caseId: idSchema.optional(),
      showCaseSelector: z.boolean().optional(),
    }),
  }),
  z.object({
    mode: z.literal('update'),
    data: z.object({
      id: idSchema,
      showCaseSelector: z.boolean().optional(),
    }),
  }),
])

// Document Opener
const createDocumentSchema = z.object({
  data: z.object({
    personId: idSchema.optional(),
    caseId: idSchema.optional(),
    showCaseSelector: z.boolean().optional(),
    institutionId: idSchema.optional(),
    outbreakId: idSchema.optional(),
    documentSchema: z.string().optional(),
  }),
})

// Institution Opener
const createOrUpdateInstitutionOpenerSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create') }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])
export type InstitutionOpener = z.infer<typeof createOrUpdateInstitutionOpenerSchema>

const createOrUpdateInstitutionMemberShipOpenerSchema = z.object({
  institutionId: z.string().nullish(),
  personId: z.string().nullish(),
  role: institutionMemberRoles.nullish(),
})

// Disease Test Opener
const createOrUpdateDiseaseTestOpenerSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: z.object({ caseId: idSchema }) }),
  z.object({ mode: z.literal('update'), data: z.object({ id: idSchema }) }),
])

// Outbreak Opener
const createOrUpdateOutbreakOpenerSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: z.object({
    caseData: z.object({
      id: idSchema,
      diseaseId: idSchema,
      infectionAddress: addressSchema.nullish(),
      contactCases: z.array(z.object({ id: idSchema })),
    }),
  }).optional() }),
  z.object({ mode: z.literal('update'), data: z.object({ id: idSchema }) }),
])

// Document Template Opener
const createOrUpdateDocumentTemplateSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create') }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])

// Filter Template Opener
const createFilterTemplateSchema = z.object({
  mode: z.literal('create'),
  data: z.object({
    group: filterTemplateGroupSchema,
    base64: z.string(),
  }),
})

// Immunizations Opener
const createOrUpdateImmunizationSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: z.object({ personId: idSchema, diseaseId: idSchema.nullish() }) }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])

// Disease Variant Opener
const createOrUpdateDiseaseVariantSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: z.object({ diseaseId: idSchema }) }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])

// Hospitalizations Opener
const createOrUpdateHospitalizationSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: z.object({ personId: idSchema.optional(), caseId: idSchema.optional() }) }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])

// Account merge Opener
const accountMergeSchema = z.object({
  id: z.string(),
  idToMergeWith: z.string().nullish(),
})

// Notes Opener
const notesDataSchema = z.object({
  personId: idSchema,
  caseId: idSchema.nullish(),
})
const createOrUpdateNoteSchema = z.discriminatedUnion('mode', [
  z.object({ mode: z.literal('create'), data: notesDataSchema }),
  z.object({ mode: z.literal('update'), data: idObjectSchema }),
])

// Team Cases Update Opener
const teamCasesUpdateOpenerSchema = z.object({
  cases: z.array(idSchema),
})
/**
 * Utilities to open globally available pages and popups.
 *
 * Can be called from anywhere to open the respective page or popup. Opening:
 * - a page will push a navigation onto the stack,
 * - a popup will be registered as open. It is important to note that the popup must be added to the page where it is used.
 *
 * - openers defined outside the exported function, are scoped globally
 * - openers defined inside the exported function, are scoped locally
 */

const openAccountMergePopup = makeOpener(accountMergeSchema)

export default () => {
  const router = useRouter()
  const { canAccessPage } = useAuthorization()

  /* Router navigation */
  const openDiseasePage = (props: z.infer<typeof createOrUpdateWithIdSchema>) => {
    router.push(`/admin/disease/${props.mode}${props.mode === 'update' ? `/${props.id}` : ''}`)
  }
  const openPersonDetailsPage = (props: { id: string }) => {
    if (canAccessPage('/users/details/*')) {
      return router.push(`/users/details/${props.id}`)
    }
    router.push({ path: '/', query: { p: props.id } })
  }
  const openCaseDetailsPage = (props: { id: string }, external?: boolean) => {
    const url = canAccessPage('/cases/details/*') ? '/cases/details' : '/self-service/cases/details'
    navigateTo(`${url}/${props.id}`, {
      ...(external ? { open: { target: '_blank' } } : {}),
    })
  }
  const openCasePage = (props: z.infer<typeof createOrUpdateCaseSchema>) => {
    if (props.mode === 'update') {
      return router.push(`/cases/update/${props.data.id}`)
    }
    router.push({ path: '/cases/create', query: props.data })
  }
  const openInstitutionDetailsPage = (props: { id: string }, external?: boolean) => {
    navigateTo(`/institutions/details/${props.id}`, {
      ...(external ? { open: { target: '_blank' } } : {}),
    })
  }
  const openOutbreakDetailsPage = (props: { id: string }) => {
    if (canAccessPage('/outbreaks/details/*')) {
      return router.push(`/outbreaks/details/${props.id}`)
    }
    router.push({ path: '/' })
  }
  const openDocumentPage = (props: { id: string }) => {
    navigateTo(`/api/file/${props.id}`, { external: true, open: { target: '_blank' } })
  }

  /* Popup handlers */
  const openAccountPopup = makeOpener(accountOpenerSchema)
  const openPersonPopup = makeOpener(createOrUpdatePersonSchema)
  const openVaccinationPopup = makeOpener(createOrUpdateVaccinationSchema)
  const openVaccinePopup = makeOpener(createOrUpdateWithIdSchema)
  const openPredispositionPopup = makeOpener(createOrUpdateWithIdSchema)
  const openSymptomPopup = makeOpener(createOrUpdateWithIdSchema)
  const openSymptomDiaryPopup = makeOpener(createOrUpdateSymptomDiaryEntrySchema)
  const openTeamsPopup = makeOpener(createOrUpdateWithIdSchema)
  const openInstitutionPopup = makeOpener(createOrUpdateInstitutionOpenerSchema)
  const openInstitutionMembershipPopup = makeOpener(createOrUpdateInstitutionMemberShipOpenerSchema)
  const openCasePopup = makeOpener(createOrUpdateCaseSchema)
  const openCaseEventPopup = makeOpener(createOrUpdateCaseEventSchema)
  const openDocumentPopup = makeOpener(createDocumentSchema)
  const openBearerTokenPopup = makeOpener(createOrUpdateWithIdSchema)
  const openDiseaseTestPopup = makeOpener(createOrUpdateDiseaseTestOpenerSchema)
  const openOutbreakPopup = makeOpener(createOrUpdateOutbreakOpenerSchema)
  const openDocumentTemplatePopup = makeOpener(createOrUpdateDocumentTemplateSchema)
  const openFilterTemplatePopup = makeOpener(createFilterTemplateSchema)
  const openInstitutionTypePopup = makeOpener(createOrUpdateWithIdSchema)
  const openImmunizationPopup = makeOpener(createOrUpdateImmunizationSchema)
  const openDiseaseVariantPopup = makeOpener(createOrUpdateDiseaseVariantSchema)
  const openHospitalizationPopup = makeOpener(createOrUpdateHospitalizationSchema)
  const openNotesDrawer = makeOpener(notesDataSchema)
  const openCreateOrUpdateNotesPopup = makeOpener(createOrUpdateNoteSchema)
  const openTeamCasesUpdatePopup = makeOpener(teamCasesUpdateOpenerSchema)

  return {
    openAccountPopup,
    openPersonPopup,
    openVaccinationPopup,
    openVaccinePopup,
    openPredispositionPopup,
    openSymptomPopup,
    openSymptomDiaryPopup,
    openTeamsPopup,
    openInstitutionPopup,
    openInstitutionMembershipPopup,
    openCaseEventPopup,
    openDiseasePage,
    openPersonDetailsPage,
    openCasePage,
    openCaseDetailsPage,
    openCasePopup,
    openDocumentPopup,
    openInstitutionDetailsPage,
    openBearerTokenPopup,
    openDiseaseTestPopup,
    openOutbreakPopup,
    openOutbreakDetailsPage,
    openDocumentTemplatePopup,
    openFilterTemplatePopup,
    openDocumentPage,
    openInstitutionTypePopup,
    openImmunizationPopup,
    openDiseaseVariantPopup,
    openHospitalizationPopup,
    openAccountMergePopup,
    openNotesDrawer,
    openCreateOrUpdateNotesPopup,
    openTeamCasesUpdatePopup,
  }
}
