<script lang="ts" setup>
import type { FilterProperty } from './schema'
import type { FilterConfig } from './configs'
import { METHOD_TYPES } from './configs'
import { EMPTY_FILTER_PROPERTY } from './defaults'
import CloseIcon from '~/assets/icons/digital/close.svg'

const props = defineProps<{
  appliedFilter: FilterProperty
  appliedFilterPath: string
  filterConfig: FilterConfig
}>()

const emit = defineEmits<{
  (e: 'set', appliedFilterPath: string, value: any): void
  (e: 'unset', appliedFilterPath: string): void
}>()

const { $i18n } = useNuxtApp()
const pathOptions = Object.keys(props.filterConfig).map(key => ({ value: key, label: props.filterConfig[key].pathLabel }))
const methodOptions = computed(() => {
  if (props.appliedFilter.path && props.filterConfig[props.appliedFilter.path]) {
    const fieldType = props.filterConfig[props.appliedFilter.path].methodType
    const disabledFieldTypes = props.filterConfig[props.appliedFilter.path].disabledFieldTypes as string[]
    if (fieldType && METHOD_TYPES[fieldType]) {
      return Object.keys(METHOD_TYPES[fieldType])
        .filter(key => disabledFieldTypes ? !disabledFieldTypes.includes(key) : true)
        .map(key => ({ value: key, label: $i18n.t(`filters.${fieldType}.${key}`) }))
    }
  }
  return []
})
const selectOptions = computed(() => {
  if (props.appliedFilter.path) {
    const options = props.filterConfig[props.appliedFilter.path].options
    if (options) {
      if (typeof options[0] === 'string') {
        const translationPrefix = props.filterConfig[props.appliedFilter.path].translationPrefix ?? 'filters.option'
        return options.map(key => ({ value: key as string, label: $i18n.t(`${translationPrefix}.${key}`) }))
      } else {
        return options as { value: string, label: string }[]
      }
    }
  }
  return []
})
</script>

<template>
  <div class="flex gap-2">
    <div class="flex-col xl:flex-row">
      <n-input-group class="flex-col xl:flex-row gap-2 xl:gap-0">
        <n-select
          class="w-[400px]"
          :value="appliedFilter.path"
          :options="pathOptions"
          @update:value="emit('set', `${appliedFilterPath}`, { ...EMPTY_FILTER_PROPERTY, path: $event })"
        />
        <n-select
          class="w-[200px]"
          :value="appliedFilter.method"
          :options="methodOptions"
          :disabled="!appliedFilter.path"
          @update:value="emit('set', `${appliedFilterPath}/method`, $event)"
        />
        <template v-if="props.appliedFilter.path && !props.filterConfig[props.appliedFilter.path].disableInput">
          <n-select
            v-if="selectOptions.length > 0"
            class="w-[300px]"
            :value="appliedFilter.value"
            :options="selectOptions"
            :multiple="props.filterConfig[props.appliedFilter.path].optionMultiSelect"
            :disabled="!appliedFilter.method"
            @update:value="emit('set', `${appliedFilterPath}/value`, $event)"
          />
          <n-date-picker
            v-else-if="appliedFilter.method === 'dateRange'"
            type="daterange"
            date-picker-format="dd-MM-yyyy"
            class="w-[400px]"
            :value="Array.isArray(appliedFilter.value) ? appliedFilter.value : undefined"
            @update:value="emit('set', `${props.appliedFilterPath}/value`, $event)"
          />
          <n-date-picker
            v-else-if="appliedFilter.method.includes('Date')"
            class="w-[200px]"
            :value="appliedFilter.value && !Array.isArray(appliedFilter.value) ? new Date(appliedFilter.value).getTime() : undefined"
            @update:value=" emit('set', `${props.appliedFilterPath}/value`, new Date($event))"
          />
          <n-input
            v-else
            class="w-[300px]"
            :value="appliedFilter.value.toString()"
            :disabled="!appliedFilter.method"
            @update:value="emit('set', `${appliedFilterPath}/value`, $event)"
          />
        </template>
      </n-input-group>
      <n-divider class="xl:hidden" />
    </div>
    <n-button circle quaternary :title="$t('filters.remove.filter')" @click="emit('unset', appliedFilterPath)">
      <div class="h-6 w-6">
        <CloseIcon />
      </div>
    </n-button>
  </div>
</template>
