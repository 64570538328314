<script lang="ts" setup>
import type { CreateFilterTemplate } from '~/types'

const props = defineProps<{ opener: typeof openFilterTemplatePopup }>()

const { $trpc, queryClient, useMutation, makeTrpcErrorToast, makeTrpcSuccessToast } = useMutationHelpers()
const { openFilterTemplatePopup } = useOpeners()
const { data: popupData, close: closePopup } = props.opener

const payload = computed((): CreateFilterTemplate | undefined => {
  if (popupData.value?.mode === 'create') {
    return {
      mode: 'create',
      data: {
        name: '',
        group: popupData.value?.data.group,
        base64: popupData.value?.data.base64,
      },
    }
  }
})

const { $i18n } = useNuxtApp()
const create = useMutation({
  mutationFn: $trpc.filterTemplates.createFilterTemplate.mutate,
  onError: makeTrpcErrorToast({ entity: $i18n.t('filters.template.entity'), mode: 'create' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['filterTemplates', 'getAll'] })
    makeTrpcSuccessToast({ entity: $i18n.t('filters.template.entity'), mode: 'create' })
    closePopup()
  },
})

const saveCreateOrUpdateForm = (payload: CreateFilterTemplate) => {
  if (payload.mode === 'create') {
    create.mutate(payload.data)
  }
}
</script>

<template>
  <ThePopup
    v-if="payload && popupData"
    :show="Boolean(payload)"
    :title="$t(`filters.template.page.${payload?.mode ?? 'create'}`)"
    @close="closePopup"
  >
    <FiltersTemplateCreateOrUpdateForm
      v-if="payload"
      :payload="payload"
      :is-loading="create.isPending.value"
      @save="saveCreateOrUpdateForm"
      @cancel="closePopup"
    />
  </ThePopup>
</template>
